<template>
  <ion-page>
    <LoggedInNav :title="'Notificaciones'" :back="true" :backUrl="'/dash/'" />

    <ion-content>
      <div class="centerCenter">
        <div class="notiContenedor">
          <div class="contenedor" style="padding: 20px; text-align: left">
            <span v-if="countNotifications > 0" class="parrafo-small">
              Tienes {{ countNotifications }} notificaciones nuevas
            </span>
            <span v-if="countNotifications == 0" class="parrafo-small">
              No tienes notificaciones nuevas
            </span>
          </div>

          <ion-list lines="none">
              <ion-item-sliding @ionDrag="sliding($event, noti.id)" v-for="noti in notifications" v-bind:key="noti.id">
                <ion-item>
                  <ion-grid class="felicitaciones">
                    <ion-row>
                      <ion-col size="2" style="display: flex; align-content: center; align-items: center;">
                        <div class="centerCenter">
                          <img src="../../../public/assets/icon/icon-success.svg" />
                        </div>
                      </ion-col>
                      <ion-col size="10">
                        <span class="parrafo" style="color: #4e833c; font-weight: bold">
                          {{ noti.title }}
                        </span>
                        <br />
                        <span class="parrafo-small" style="color: #4e833c">
                          {{ noti.message }} </span
                        ><br />
                        <ion-button
                          v-if="noti.link != ''"
                          type="submit"
                          class="felicitacionesButton"
                          color="none"
                          >{{ noti.link }}</ion-button>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-item>

                <ion-item-options side="end">
                  <ion-item-option
                    color="danger"
                    v-on:click="deleteNoti(noti.id)"
                  >
                    <ion-icon slot="icon-only" :icon="trash"></ion-icon>
                  </ion-item-option>
                </ion-item-options>

              </ion-item-sliding>
          </ion-list>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
} from "@ionic/vue";
import { trash } from "ionicons/icons";
import LoggedInNav from "../../components/LoggedInNav";
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Notificaciones",
  components: {
    IonPage,
    LoggedInNav,
    IonContent,
    IonIcon,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonList,
    IonCol,
    IonGrid,
    IonRow,
    IonButton,
  },
  setup() {
    const store = useStore();
    const notificaciones = store.getters["Notification/getNotifications"];

    return {
      trash,
      notificaciones,
      store,
    };
  },
  computed: {
    notifications() {
      return this.notificaciones;
    },
    countNotifications() {
      return this.notificaciones.length;
    },
  },
  methods: {
    deleteNoti(id) {
      this.store.commit("Notification/setViewed", id);
    },
    sliding(data, id) {
      if (data.detail.ratio >= 2) {       
        this.deleteNoti(id);
      }
    },
  },
});
</script>

<style scoped>
ion-button {
  --padding-top: 4px !important;
}

ion-item {
    border-radius: 0px;
    --background: #cfeac6;
    margin-top: 0px;
}

.felicitaciones {
  background-color: #cfeac6;
  margin: 0px 0;
}

.felicitacionesButton {
  box-shadow: 0px 10px 14px -7px #c5c5c5;
  background-color: white;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  color: #4e833c;
  font-family: "Avenir";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-decoration: none;
  margin-top: 10px;
}

.recordatorio {
  background-color: #fff0d4;
  padding: 5px 20px 20px 20px;
}

.recordatorioButton {
  box-shadow: 0px 10px 14px -7px #c5c5c5;
  background-color: white !important;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  color: #c08b26;
  font-family: "Avenir";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-decoration: none;
  margin-top: 10px;
}

.advertencia {
  background-color: #fad9c6;
  padding: 5px 20px 20px 20px;
}

.advertenciaButton {
  box-shadow: 0px 10px 14px -7px #c5c5c5;
  background-color: white;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  color: #d46827;
  font-family: "Avenir";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-decoration: none;
  margin-top: 10px;
}

ion-row {
  text-align: left;
}

.list-md {
    padding-top: 0px; 
    padding-bottom: 0px;
}

.notiContenedor {
  width: 100%;
}
@media (min-width: 750px) {
  .notiContenedor {
    width: 720px;
  }
}

</style>